// Fill timezone
document.addEventListener('DOMContentLoaded', function () {
    let tz = moment.tz.guess();
    let timezone_input = $('#timezone');
    if (tz && timezone_input.length) {
        timezone_input.val(tz);
    }
});

// Mobile menu
document.addEventListener('DOMContentLoaded', function () {
    $('.mobilBtn').on('click', function () {
        $(this).toggleClass('active');
        $('.mobilBlockMenu').slideToggle();
        $('body').toggleClass('hide');
        $('.fon').toggleClass('active');
    });

    $('.fon').on('click', function () {
        $('body').removeClass('hide');
        $(this).removeClass('active');
        $('.mobilBlockMenu').slideUp();
        $('.mobilBtn').removeClass('active');
        $('.footerGnu .footerGnuBottomLangIn').slideUp();
        $('.header .footerGnuBottomLangIn').slideUp();
    });
});

// Mobile menu
document.addEventListener('DOMContentLoaded', function () {
    let userBtn = document.querySelector('.userBtn');
    let menu = document.querySelector('.userList');

    const toggleMenu = () => {
        menu.classList.toggle('active');
    };

    userBtn?.addEventListener('click', e => {
        e.stopPropagation();
        toggleMenu();
    });

    document.addEventListener('click', e => {
        let target = e.target;
        let its_menu = target == menu || menu?.contains(target);
        let its_hamburger = target == userBtn;
        let menu_is_active = menu?.classList.contains('active');

        if (!its_menu && !its_hamburger && menu_is_active) {
            toggleMenu();
        }
    });
});

// language select
document.addEventListener('DOMContentLoaded', function () {
    function languageMenu(button, dropdown) {
        let userBtnLang = document.querySelector(button);
        let menuLang = document.querySelector(dropdown);

        const toggleMenuLang = () => {
            menuLang?.classList.toggle('active');
        };

        userBtnLang?.addEventListener('click', e => {
            e.stopPropagation();
            toggleMenuLang();
        });

        document.addEventListener('click', e => {
            let target = e.target;
            let its_menu = target == menuLang || menuLang?.contains(target);
            let its_hamburger = target == userBtnLang;
            let menu_is_active = menuLang?.classList.contains('active');

            if (!its_menu && !its_hamburger && menu_is_active) {
                toggleMenuLang();
            }
        });
    }

    languageMenu('.footerGnuBottomLang__eng', '.footerGnuBottomLangIn');
    languageMenu('.mobilBlockMenu .footerGnuBottomLang__eng', '.mobilBlockMenu .footerGnuBottomLangIn');
    languageMenu('.footerGnu .footerGnuBottomLang__eng', '.footerGnu .footerGnuBottomLangIn');
});

document.addEventListener('DOMContentLoaded', function () {
    // Define dataLayer and the gtag function.
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}

    function consentConfirm() {
        if (typeof gtag === 'function') {
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'analytics_storage': 'granted',
                'functionality_storage': 'granted',
                'personalization_storage': 'granted',
                'security_storage': 'granted'
            });
            console.log('gtag consent updated');
        } else {
            console.log('no gtag');
            window.setTimeout(consentConfirm, 1000);
        }
    }

    $('.lang_switcher').click(function () {
        $.cookie('locale', $(this).data('locale'), {expires: 7, path: '/'});
    });

    if (!$.cookie('GDPR')) {
        $('.cookiesPopup').show();
    } else {
        consentConfirm();
    }

    $('.cookiesPopup__btn').click(function () {
        $.cookie('GDPR', true, {expires: 7, path: '/'});
        consentConfirm();
        $('.cookiesPopup').hide();
    });
});

// Google Analytics
document.addEventListener('DOMContentLoaded', function () {
    if ($('.is_auth').length) {
        const ga = $.cookie('_ga');
        if (ga) {
            $.get(STORE_GA_URL, {
                _token: $('meta[name="csrf-token"]').attr('content'),
                client_id: ga.split('.')[2] + '.' + ga.split('.')[3],
            });
        }
    }
});

// Checking password
document.addEventListener('DOMContentLoaded', function () {
    let min = $('#min');
    let upper = $('#upper');
    let lower = $('#lower');
    let number = $('#number');

    function containsUppercase(str) {
        return /[A-ZА-Я]/.test(str);
    }

    function containsLowercase(str) {
        return /[a-zа-я]/.test(str);
    }

    function containsNumber(str) {
        return /[0-9]/.test(str);
    }

    function passwordLength(str) {
        return str.length >= 8;
    }

    $('#password2').on('keyup paste change', function () {
        const pass = $(this).val();

        passwordLength(pass) ? min.removeClass('nocorect__characters') : min.addClass('nocorect__characters');
        containsUppercase(pass) ? upper.removeClass('nocorect__Uppercase') : upper.addClass('nocorect__Uppercase');
        containsLowercase(pass) ? lower.removeClass('nocorect__Lowercase') : lower.addClass('nocorect__Lowercase');
        containsNumber(pass) ? number.removeClass('nocorect__Number') : number.addClass('nocorect__Number');
    });
});

// Password show/hide init
document.addEventListener('DOMContentLoaded', function () {
    $('.password_eye_btn').click(function () {
        if ($(this).hasClass('eye_opened')) {
            $(this).removeClass('eye_opened');
            $(this).prev('input').attr('type', 'password');
        } else {
            $(this).addClass('eye_opened');
            $(this).prev('input').attr('type', 'text');
        }
    });
});

// Copying
document.addEventListener('DOMContentLoaded', function () {
    if (typeof ClipboardJS != 'undefined') {
        new ClipboardJS('[data-clipboard-text]');
    }
});
